$shed-pseudo-states: n, active, focus, hover, visited;

$colors: (
  "white": #fff,
  "black": #000,
  "yellow": #ffd000,
  "blue": #373f66,
  "orange": #e84329,
  "success": #4fb420,
  "alert": #f90808,
  "bright-blue": #aaf0e7,
  "transparent": rgba(255, 255, 255, 0),
  "dm-gray": rgb(170, 173, 187),
  "dark-gray": rgba(129, 131, 141, 1),
);
