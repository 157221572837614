@import "variables";
@import "mixins";
@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@400;500;600;700&display=swap");

@include generate-theme($colors);

.ant-layout {
  min-height: unset;
}
.App {
  height: 100%;
  display: flex;
  flex-direction: column;
}
body {
  margin: 0;
  font-family: "Inter";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  .label {
    color: #373f66;
  }
}
.demy-icon {
  top: 0.125em;
  position: relative;
  svg {
    width: 1em;
    height: 1em;
  }
}

.b-r\:base {
  border-radius: var(--base-radius);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
:root {
  --base-radius: 6px;
}

#root {
  height: 100%;
  .label {
    color: #373f66;
  }
}

.absolute-center {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.absolute-center-y {
  top: 50%;
  transform: translateY(-50%);
}

.Main__content {
  background: #fbfbfb;
}
