@mixin generate-theme($theme_colors) {
  :root {
    @each $key, $value in $colors {
      --#{$key}: #{$value};
    }
  }
  @each $label, $color in $theme_colors {
    @each $state in $shed-pseudo-states {
      @if $state == n {
        .bg\:#{$label} {
          background: $color;
        }

        .c\:#{$label} {
          color: $color;
        }

        @for $i from 1 through 9 {
          .bg\:#{$label}\.#{$i} {
            background: transparentize($color, ($i / 10));
          }

          .c\:#{$label}\.#{$i} {
            color: transparentize($color, ($i / 10));
          }
        }
      } @else {
        .#{$state}\/bg\:#{$label}:#{$state} {
          background: $color;
        }

        @for $i from 1 through 9 {
          .#{$state}\/bg\:#{$label}\.#{$i}:#{$state} {
            background: transparentize($color, ($i / 10));
          }
        }
      }
    }
  }
}
